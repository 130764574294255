// FontAwesome icons

// Use plugins to only use the features we need
// Old import: import { config, dom, library } from 'Vendor/@fortawesome/fontawesome-svg-core'
// Docs: https://fontawesome.com/docs/apis/javascript/plugins
import {
	InjectCSS,
	Layers,
	// LayersCounter,
	// LayersText,
	// Masks,
	MutationObserver,
	PowerTransforms,
	register,
	ReplaceElements,
} from 'Vendor/@fortawesome/fontawesome-svg-core/plugins.mjs';

const api = register([
	InjectCSS,
	Layers,
	// LayersCounter,
	// LayersText,
	// Masks,
	MutationObserver,
	PowerTransforms,
	ReplaceElements,
])

api.config.autoReplaceSvg = 'nest';
api.config.observeMutations = true;

// Solid icons
// Core
import { faAngleLeft          } from 'Vendor/@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight         } from 'Vendor/@fortawesome/free-solid-svg-icons/faAngleRight';
import { faCaretDown          } from 'Vendor/@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp            } from 'Vendor/@fortawesome/free-solid-svg-icons/faCaretUp';
import { faCheck              } from 'Vendor/@fortawesome/free-solid-svg-icons/faCheck';
import { faCircle             } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircle';
import { faCircleArrowUp      } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleArrowUp';
import { faCircleCheck        } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faCircleMinus        } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleMinus';
import { faCirclePlus         } from 'Vendor/@fortawesome/free-solid-svg-icons/faCirclePlus';
import { faCircleXmark        } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleXmark';
import { faList               } from 'Vendor/@fortawesome/free-solid-svg-icons/faList';
import { faMagnifyingGlass    } from 'Vendor/@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { faMinus              } from 'Vendor/@fortawesome/free-solid-svg-icons/faMinus';
import { faPlus               } from 'Vendor/@fortawesome/free-solid-svg-icons/faPlus';
import { faSpinner            } from 'Vendor/@fortawesome/free-solid-svg-icons/faSpinner';
import { faStar               } from 'Vendor/@fortawesome/free-solid-svg-icons/faStar';
import { faStarHalfStroke     } from 'Vendor/@fortawesome/free-solid-svg-icons/faStarHalfStroke';
import { faUpRightFromSquare  } from 'Vendor/@fortawesome/free-solid-svg-icons/faUpRightFromSquare';
import { faXmark              } from 'Vendor/@fortawesome/free-solid-svg-icons/faXmark';

// Account icons
import { faAsterisk           } from 'Vendor/@fortawesome/free-solid-svg-icons/faAsterisk';
import { faEnvelope           } from 'Vendor/@fortawesome/free-solid-svg-icons/faEnvelope';
import { faGear               } from 'Vendor/@fortawesome/free-solid-svg-icons/faGear';
import { faRotate             } from 'Vendor/@fortawesome/free-solid-svg-icons/faRotate';
import { faUser               } from 'Vendor/@fortawesome/free-solid-svg-icons/faUser';
import { faUserGear           } from 'Vendor/@fortawesome/free-solid-svg-icons/faUserGear';

// Charity categories
import { faChild              } from 'Vendor/@fortawesome/free-solid-svg-icons/faChild';
import { faEarthAfrica        } from 'Vendor/@fortawesome/free-solid-svg-icons/faEarthAfrica';
import { faFutbol             } from 'Vendor/@fortawesome/free-solid-svg-icons/faFutbol';
import { faGraduationCap      } from 'Vendor/@fortawesome/free-solid-svg-icons/faGraduationCap';
import { faHandshakeAngle     } from 'Vendor/@fortawesome/free-solid-svg-icons/faHandshakeAngle';
import { faHelicopter         } from 'Vendor/@fortawesome/free-solid-svg-icons/faHelicopter';
import { faLeaf               } from 'Vendor/@fortawesome/free-solid-svg-icons/faLeaf';
import { faPalette            } from 'Vendor/@fortawesome/free-solid-svg-icons/faPalette';
import { faPaw                } from 'Vendor/@fortawesome/free-solid-svg-icons/faPaw';
import { faPlaceOfWorship     } from 'Vendor/@fortawesome/free-solid-svg-icons/faPlaceOfWorship';
import { faStethoscope        } from 'Vendor/@fortawesome/free-solid-svg-icons/faStethoscope';
import { faUsers              } from 'Vendor/@fortawesome/free-solid-svg-icons/faUsers';
import { faWheelchair         } from 'Vendor/@fortawesome/free-solid-svg-icons/faWheelchair';

// Page types
import { faCakeCandles        } from 'Vendor/@fortawesome/free-solid-svg-icons/faCakeCandles';
import { faDove               } from 'Vendor/@fortawesome/free-solid-svg-icons/faDove';
import { faHouseUser          } from 'Vendor/@fortawesome/free-solid-svg-icons/faHouseUser';
import { faMedal              } from 'Vendor/@fortawesome/free-solid-svg-icons/faMedal';

// Page stuff
import { faBullseye           } from 'Vendor/@fortawesome/free-solid-svg-icons/faBullseye';
import { faCalendarDays       } from 'Vendor/@fortawesome/free-solid-svg-icons/faCalendarDays';
import { faCommentDots        } from 'Vendor/@fortawesome/free-solid-svg-icons/faCommentDots';
import { faCompress           } from 'Vendor/@fortawesome/free-solid-svg-icons/faCompress';
import { faFlag               } from 'Vendor/@fortawesome/free-solid-svg-icons/faFlag';
import { faGlobe              } from 'Vendor/@fortawesome/free-solid-svg-icons/faGlobe';
import { faImages             } from 'Vendor/@fortawesome/free-solid-svg-icons/faImages';
import { faLightbulb          } from 'Vendor/@fortawesome/free-solid-svg-icons/faLightbulb';
import { faLink               } from 'Vendor/@fortawesome/free-solid-svg-icons/faLink';
import { faLocationDot        } from 'Vendor/@fortawesome/free-solid-svg-icons/faLocationDot';
import { faQuoteLeft          } from 'Vendor/@fortawesome/free-solid-svg-icons/faQuoteLeft';
import { faQuoteRight         } from 'Vendor/@fortawesome/free-solid-svg-icons/faQuoteRight';
import { faSterlingSign       } from 'Vendor/@fortawesome/free-solid-svg-icons/faSterlingSign';
import { faTrashCan           } from 'Vendor/@fortawesome/free-solid-svg-icons/faTrashCan';
import { faWandMagicSparkles  } from 'Vendor/@fortawesome/free-solid-svg-icons/faWandMagicSparkles';

// Media gallery types
import { faPersonRunning      } from 'Vendor/@fortawesome/free-solid-svg-icons/faPersonRunning';

// How it works
import { faCreditCard         } from 'Vendor/@fortawesome/free-solid-svg-icons/faCreditCard';
import { faHeart              } from 'Vendor/@fortawesome/free-solid-svg-icons/faHeart';
import { faIdCard             } from 'Vendor/@fortawesome/free-solid-svg-icons/faIdCard';
import { faPiggyBank          } from 'Vendor/@fortawesome/free-solid-svg-icons/faPiggyBank';

// Navigation
import { faBars               } from 'Vendor/@fortawesome/free-solid-svg-icons/faBars';
import { faCircleInfo         } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleInfo';
import { faHouse              } from 'Vendor/@fortawesome/free-solid-svg-icons/faHouse';
import { faLock               } from 'Vendor/@fortawesome/free-solid-svg-icons/faLock';
import { faPencil             } from 'Vendor/@fortawesome/free-solid-svg-icons/faPencil';
import { faRightFromBracket   } from 'Vendor/@fortawesome/free-solid-svg-icons/faRightFromBracket';
import { faRightToBracket     } from 'Vendor/@fortawesome/free-solid-svg-icons/faRightToBracket';

// Sharing
import { faBullhorn           } from 'Vendor/@fortawesome/free-solid-svg-icons/faBullhorn';
import { faCopy               } from 'Vendor/@fortawesome/free-solid-svg-icons/faCopy';
import { faQrcode             } from 'Vendor/@fortawesome/free-solid-svg-icons/faQrcode';

// Markdown editor
import { faBold               } from 'Vendor/@fortawesome/free-solid-svg-icons/faBold';
import { faHeading            } from 'Vendor/@fortawesome/free-solid-svg-icons/faHeading';
import { faItalic             } from 'Vendor/@fortawesome/free-solid-svg-icons/faItalic';
import { faListOl             } from 'Vendor/@fortawesome/free-solid-svg-icons/faListOl';
import { faRotateLeft         } from 'Vendor/@fortawesome/free-solid-svg-icons/faRotateLeft';
import { faRotateRight        } from 'Vendor/@fortawesome/free-solid-svg-icons/faRotateRight';

// Others
import { faAnglesUp           } from 'Vendor/@fortawesome/free-solid-svg-icons/faAnglesUp';
import { faBasketShopping     } from 'Vendor/@fortawesome/free-solid-svg-icons/faBasketShopping';
import { faBriefcase          } from 'Vendor/@fortawesome/free-solid-svg-icons/faBriefcase';
import { faCalculator         } from 'Vendor/@fortawesome/free-solid-svg-icons/faCalculator';
import { faChartLine          } from 'Vendor/@fortawesome/free-solid-svg-icons/faChartLine';
import { faChartPie           } from 'Vendor/@fortawesome/free-solid-svg-icons/faChartPie';
import { faCircleExclamation  } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { faCircleQuestion     } from 'Vendor/@fortawesome/free-solid-svg-icons/faCircleQuestion';
import { faCloudArrowUp       } from 'Vendor/@fortawesome/free-solid-svg-icons/faCloudArrowUp';
import { faGift               } from 'Vendor/@fortawesome/free-solid-svg-icons/faGift';
import { faHourglassHalf      } from 'Vendor/@fortawesome/free-solid-svg-icons/faHourglassHalf';
import { faLaptop             } from 'Vendor/@fortawesome/free-solid-svg-icons/faLaptop';
import { faMobileScreenButton } from 'Vendor/@fortawesome/free-solid-svg-icons/faMobileScreenButton';
import { faMoneyBill          } from 'Vendor/@fortawesome/free-solid-svg-icons/faMoneyBill';
import { faPhone              } from 'Vendor/@fortawesome/free-solid-svg-icons/faPhone';
import { faQuestion           } from 'Vendor/@fortawesome/free-solid-svg-icons/faQuestion';
import { faTicket             } from 'Vendor/@fortawesome/free-solid-svg-icons/faTicket';
import { faTree               } from 'Vendor/@fortawesome/free-solid-svg-icons/faTree';
import { faTrophy             } from 'Vendor/@fortawesome/free-solid-svg-icons/faTrophy';

api.library.add({
	// Core
	faAngleLeft,
	faAngleRight,
	faCaretDown,
	faCaretUp,
	faCheck,
	faCircle,
	faCircleArrowUp,
	faCircleCheck,
	faCircleMinus,
	faCirclePlus,
	faCircleXmark,
	faList,
	faMagnifyingGlass,
	faMinus,
	faPlus,
	faSpinner,
	faStar,
	faStarHalfStroke,
	faUpRightFromSquare,
	faXmark,

	// Account icons
	faAsterisk,
	faEnvelope,
	faGear,
	faRotate,
	faUser,
	faUserGear,

	// Charity categories
	faChild,
	faEarthAfrica,
	faFutbol,
	faGraduationCap,
	faHandshakeAngle,
	faHelicopter,
	faLeaf,
	faPalette,
	faPaw,
	faPlaceOfWorship,
	faStethoscope,
	faUsers,
	faWheelchair,

	// Page types
	faCakeCandles,
	faDove,
	faHouseUser,
	faMedal,

	// Page stuff
	faBullseye,
	faCalendarDays,
	faCommentDots,
	faCompress,
	faFlag,
	faGlobe,
	faImages,
	faLightbulb,
	faLink,
	faLocationDot,
	faQuoteLeft,
	faQuoteRight,
	faSterlingSign,
	faTrashCan,
	faWandMagicSparkles,

	// Media gallery types
	faPersonRunning,

	// How it works
	faCreditCard,
	faHeart,
	faIdCard,
	faPiggyBank,

	// Navigation
	faBars,
	faCircleInfo,
	faHouse,
	faLock,
	faPencil,
	faRightFromBracket,
	faRightToBracket,

	// Sharing
	faBullhorn,
	faCopy,
	faQrcode,

	// Markdown editor
	faBold,
	faHeading,
	faItalic,
	faListOl,
	faRotateLeft,
	faRotateRight,

	// Others
	faAnglesUp,
	faBasketShopping,
	faBriefcase,
	faCalculator,
	faChartLine,
	faChartPie,
	faCircleExclamation,
	faCircleQuestion,
	faCloudArrowUp,
	faGift,
	faHourglassHalf,
	faLaptop,
	faMobileScreenButton,
	faMoneyBill,
	faPhone,
	faQuestion,
	faTicket,
	faTree,
	faTrophy,
});

// Regular icons
import { faHeart as farHeart } from 'Vendor/@fortawesome/free-regular-svg-icons/faHeart';

api.library.add({
	farHeart,
})

// Brand icons
import { faApple             } from 'Vendor/@fortawesome/free-brands-svg-icons/faApple';
import { faFacebook          } from 'Vendor/@fortawesome/free-brands-svg-icons/faFacebook';
import { faFacebookF         } from 'Vendor/@fortawesome/free-brands-svg-icons/faFacebookF';
import { faFacebookMessenger } from 'Vendor/@fortawesome/free-brands-svg-icons/faFacebookMessenger';
import { faInstagram         } from 'Vendor/@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn        } from 'Vendor/@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faStrava            } from 'Vendor/@fortawesome/free-brands-svg-icons/faStrava';
import { faTwitch            } from 'Vendor/@fortawesome/free-brands-svg-icons/faTwitch';
import { faTwitter           } from 'Vendor/@fortawesome/free-brands-svg-icons/faTwitter';
import { faWhatsapp          } from 'Vendor/@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faYoutube           } from 'Vendor/@fortawesome/free-brands-svg-icons/faYoutube';

api.library.add({
	faApple,
	faFacebook,
	faFacebookF,
	faFacebookMessenger,
	faInstagram,
	faLinkedinIn,
	faStrava,
	faTwitch,
	faTwitter,
	faWhatsapp,
	faYoutube,
});

// Add our own icons
// import { faFitbit      } from 'Scripts/common/icons/faFitbit';
// import { faGarmin      } from 'Scripts/common/icons/faGarmin';
// import { faPacer       } from 'Scripts/common/icons/faPacer';
// import { faUnderArmour } from 'Scripts/common/icons/faUnderArmour';

// api.library.add({
	// faFitbit, 
	// faGarmin, 
	// faPacer, 
	// faUnderArmour,
// });

// Replace and watch
api.dom.i2svg();
api.dom.watch();